import img2 from './../assets/Zhihao_Tina-2.jpg'
import img3 from './../assets/Zhihao_Tina-3.jpg'
import img4 from './../assets/Zhihao_Tina-4.jpg'
import img5 from './../assets/Zhihao_Tina-5.jpg'
import img6 from './../assets/Zhihao_Tina-6.jpg'
import img7 from './../assets/Zhihao_Tina-7.jpg'
import imgLayingdown from './../assets/Zhihao_Tina-Layingdown.jpeg'

export const SliderData = [
  {
    image: img2
  },
  {
    image: img3
  },
  {
    image: img4
  },
  {
    image: img5
  },
  {
    image: img6
  },
  {
    image: img7
  },
  {
    image: imgLayingdown
  },
];